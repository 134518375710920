<template>
  <div class="container">
    <div class="login-form">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="input-group">
          <label for="username">Benutzername</label>
          <input type="text" id="username" v-model="username" required>
        </div>
        <div class="input-group">
          <label for="password">Passwort</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit">Anmelden</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: 'daniel',
      password: ''
    };
  },
  methods: {
    login() {
      if (this.username === 'daniel' && this.password === 'dnl123') {
        if (this.$route.path !== '/pflegegrad') {
          this.$router.push('/pflegegrad');
        }
      } else {
        alert('Falscher Benutzername oder Passwort');
      }
    }
  }


};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-form {
  padding: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.login-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
