
import {defineComponent} from "vue";
import PBHeader from "@/components/PB-Header.vue";
import PBFooter from "@/components/PBFooter.vue";
import releaseJson from "@/internaldata/release.json"
import {mapFields} from "vuex-map-fields";



export default defineComponent({
  components: {PBFooter, PBHeader},

  data: () => ({
    showPopup: false,

    popupMessage: "",
    germanNames: [
      "Tom", "Anna", "Felix", "Julia", "Lukas", "Sophie", "Max", "Laura", "Leon", "Lena",
      "Paul", "Mia", "Elias", "Sarah", "David", "Emma", "Noah", "Lisa", "Tim", "Marie",
      "Jonas", "Lea", "Ben", "Clara", "Samuel", "Lara", "Moritz", "Hanna", "Anton", "Isabella"
    ],
    germanCities: ["Berlin", "Hamburg", "München", "Köln", "Stuttgart", "Düsseldorf", "Dresden", "Leipzig", "Frankfurt", "Nürnberg"]
  }),

  mounted() {
    const prefill = this.$route.query.prefill;

    if (this.$vuetify.breakpoint.mdAndUp) {
      // Erstes Popup nach 15 Sekunden
      setTimeout(() => {
        this.showUserActionPopup();

        // Anschließende Popups alle 60 Sekunden
        setInterval(this.showUserActionPopup, 60000);
      }, 15000);
    }


    if (prefill) {
      const jsonString = atob(prefill.toString());
      const storeOverride = JSON.parse(jsonString)
      this.$store.commit("overrideStore", storeOverride)
    } else console.warn("prefill failed object is empty")
  },

  methods: {
    fillLocalStoreWithTestData() {
    },

    getRandomFromArray(array: any[]): any {
      return array[Math.floor(Math.random() * array.length)];
    },




    showUserActionPopup() {
      const randomName = this.getRandomFromArray(this.germanNames);
      const userCity = this.getRandomFromArray(this.germanCities);  // Zufällige Stadt aus der Liste auswählen

      this.popupMessage = `${randomName} aus ${userCity} hat soeben ein Hilfsmittelantrag abgeschlossen`;
      this.showPopup = true;

      // Popup nach 5 Sekunden ausblenden
      setTimeout(() => {
        this.showPopup = false;
      }, 5000);
    }



  },

  computed: {
    ...mapFields([""]),

    release() {
      return releaseJson
    },

    isLocal() {
      return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    }
  }
})

