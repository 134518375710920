import {Product} from "@/models/Product";

export const defaultProducts: Product[] = [
    {
        id: 'EH',
        title: "Einmalhandschuhe",
        manufacturer: "docdorado",
        packagingUnit: "100 Stk.",
        image: '/static/images/Nitrilhandschuhe.webp',
        quantity: 0,
        value: 7.18,
        selectedVariant: { id: 'NM', material: 'Nitril', size: 'M' }, // Setze eine Standardvariante
        variantOptions: [
            { id: 'VS', material: 'Vinyl', size: 'S' },
            { id: 'VM', material: 'Vinyl', size: 'M' },
            { id: 'VL', material: 'Vinyl', size: 'L' },
            { id: 'VXL', material: 'Vinyl', size: 'XL' },
            { id: 'NS', material: 'Nitril', size: 'S' },
            { id: 'NM', material: 'Nitril', size: 'M' },
            { id: 'NL', material: 'Nitril', size: 'L' },
            { id: 'NXL', material: 'Nitril', size: 'XL' },
            { id: 'LS', material: 'Latex', size: 'S' },
            { id: 'LM', material: 'Latex', size: 'M' },
            { id: 'LL', material: 'Latex', size: 'L' },
            { id: 'LXL', material: 'Latex', size: 'XL' }
        ]
    },
    { id: "F", packagingUnit: "1 Stk.", title: "FFP 2 Maske",manufacturer: "Famex",image: '/static/images/FFP2.webp', quantity: 0, value: 1 },
    { id: "M50", packagingUnit: "50 Stk.",title: "Medizinischer Mundschutz",manufacturer: "Meditrade", image: '/static/images/Mundschutz.webp', quantity: 0, value: 10 },
    { id: "HD", packagingUnit: "500 ml",title: "Händedesinfektion",manufacturer: "docdorado", image: '/static/images/docsept.webp', quantity: 0, value: 10 },
    { id: "FD", packagingUnit: "500 ml",title: "Flächendesinfektion",manufacturer: "docdorado", image: '/static/images/disinfectX.webp', quantity: 0, value: 10 },
    { id: "ES", packagingUnit: "100 Stk.",title: "Schutzschürzen",manufacturer: "Meditrade", image: '/static/images/Schuerzen.webp', quantity: 0, value: 10 },
    { id: "B", packagingUnit: "25 Stk.",title: "Bettschutz",manufacturer: "docdorado", image: '/static/images/superdry.webp', quantity: 0, value: 10 },
    { id: "WB", packagingUnit: "1 Stk.",title: "Wiederverwendbarer Bettschutz",manufacturer: "Maimed", image: '/static/images/WVBettschutz.webp', quantity: 0, value: 0 },
    { id: "W", packagingUnit: "50 Stk.",title: "Einmal-Waschhandschue",manufacturer: "Meditrade", image: '/static/images/Waschhandschuhe.webp', quantity: 0, value: 10},
    { id: "FI", packagingUnit: "1 Stk.",title: "Fingerlinge",manufacturer: "Ampri", image: '/static/images/Fingerlinge.webp', quantity: 0, value: 10 },

    { id: "SeniLadyPlus", packagingUnit: "15 Stück",title: "SENI Lady Plus Inkontinenzeinlagen",manufacturer: "Seni", image: '/static/images/seni-lady-plus-inkontinenzeinlagen-extra-stark.jpg', quantity: 0, value: 0 },
    { id: "SeniManExtra", packagingUnit: "15 Stück",title: "Seni Man Extra",manufacturer: "Seni", image: '/static/images/Seni_Man_Extra_2_0f5c2f88f23bd558821e8c13e4695fd4_1920x1920.png', quantity: 0, value: 0 },
    { id: "SeniNormal", packagingUnit: "10 Stück",title: "San SENI Normal Vorlagen",manufacturer: "Seni", image: '/static/images/san_seni_normal_scala_1920x1920.png', quantity: 0, value: 0 },


    { id: "AbenaPantsS1", packagingUnit: "16 Stück",title: "ABENA Pants S1 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-s1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsS2", packagingUnit: "16 Stück",title: "ABENA Pants S2 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-s1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsM0", packagingUnit: "15 Stück",title: "ABENA Pants M0 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-m0_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsM1", packagingUnit: "15 Stück",title: "ABENA Pants M1 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-m0_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsM2", packagingUnit: "15 Stück",title: "ABENA Pants M2 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-m0_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsM3", packagingUnit: "15 Stück",title: "ABENA Pants M3 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-m0_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsL1", packagingUnit: "15 Stück",title: "ABENA Pants L1 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-l1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsL2", packagingUnit: "15 Stück",title: "ABENA Pants L2 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-l1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsL3", packagingUnit: "15 Stück",title: "ABENA Pants L3 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-l1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsXL1", packagingUnit: "16 Stück",title: "ABENA Pants XL1 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-xl1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsXL2", packagingUnit: "16 Stück",title: "ABENA Pants XL2 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-xl1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "AbenaPantsXL3", packagingUnit: "16 Stück",title: "ABENA Pants XL3 Premium",manufacturer: "Abena", image: '/static/images/abena-pants-xl1_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "MolicareSkinHandscreme", packagingUnit: "250ml",title: "Molicare Skin Handcreme",manufacturer: "Molicare", image: '/static/images/molicare-skin-handcreme_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "MolicareSkinKoerperlotion", packagingUnit: "250ml",title: "Molicare Skin Körperlotion",manufacturer: "Molicare", image: '/static/images/molicare-skin-body-lotion-250ml_1920x1920.jpg', quantity: 0, value: 0 },
    { id: "GentleMedAktivGel", packagingUnit: "500ml",title: "Aktiv Gel",manufacturer: "Meditrade", image: '/static/images/Gentle_Med_Aktivgel_500ml_1920x1920.jpg', quantity: 0, value: 0 },

    { id: "Elina1", packagingUnit: "250ml",title: "Duschgel",manufacturer: "Elina", image: '/static/images/Duschgel.webp', quantity: 0, value: 0 },
    { id: "Elina2", packagingUnit: "250ml",title: "Shampoo",manufacturer: "Elina", image: '/static/images/Shampoo.webp', quantity: 0, value: 0 },
    { id: "Elina3", packagingUnit: "75ml",title: "Handcreme",manufacturer: "Elina", image: '/static/images/Handcreme.webp', quantity: 0, value: 0 },
    { id: "Elina4", packagingUnit: "75ml",title: "Fußcreme",manufacturer: "Elina", image: '/static/images/Fußcreme.webp', quantity: 0, value: 0 },
    { id: "GE", packagingUnit: "80 Stk.",title: "Feuchttücher",manufacturer: "Meditrade", image: '/static/images/Feuchttuecher.webp', quantity: 0, value: 0 },



    { id: "GC", packagingUnit: "10ml",title: "Skin Waschlotion",manufacturer: "Molicare", image: '/static/images/molicare-waschlotion.webp', quantity: 0, value: 0 },
    { id: "GB", packagingUnit: "10ml",title: "Skin Körperlotion",manufacturer: "Molicare", image: '/static/images/molicare-koerperlotion.webp', quantity: 0, value: 0 },
    { id: "GA", packagingUnit: "10ml",title: "Skin Hautschutzcreme",manufacturer: "Molicare", image: '/static/images/molicare-BodyLotion.webp', quantity: 0, value: 0 }
]